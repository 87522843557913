<script>
import Form, { formCreate } from '../../../../../components/form';
import request from '../../../../../utils/request';

import customerSaleArea from '../components/sale_area/sale_area.vue';
import contactInfo from '../components/contact_info/contact_info.vue';
import ExtendedInfo from '../components/extended_info/extend_info.vue';
import invoiceInfo from '../components/invoice_info/invoice_info.vue';
import customerUser from '../components/customer_user/customer_user.vue';
import terminalAdrInput from '../components/adr_input.vue';
import OverviewOfCooperation from '../components/overview_of_cooperation/overview_of_cooperation.vue';
import SelfOperatedStores from '../components/self_operated_stores.vue';
import PrivateWarehouse from '../components/private_warehouse.vue';
import BrandSalesRevenue from '../components/brand_sales_revenue.vue';

formCreate.component('customerSaleArea', customerSaleArea);
formCreate.component('contactInfo', contactInfo);
formCreate.component('ExtendedInfo', ExtendedInfo);
formCreate.component('invoiceInfo', invoiceInfo);
formCreate.component('customerUser', customerUser);
formCreate.component('terminalAdrInput', terminalAdrInput);
formCreate.component('OverviewOfCooperation', OverviewOfCooperation);
formCreate.component('SelfOperatedStores', SelfOperatedStores);
formCreate.component('PrivateWarehouse', PrivateWarehouse);
formCreate.component('BrandSalesRevenue', BrandSalesRevenue);

export default {
  extends: Form,
  props: {
    id: String,
    noButton: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      provinceList: [],
      areaList: [],
      cityList: [],
      rule: [],
      mdmCustomerSaleAreaVos: [
        {
          areaOne: '',
          areaTwo: '',
          areaThree: '',
          id: new Date().getTime(),
        },
      ],

      mdmCustomerContactVos: [
        {
          contactMain: '1',
          contactName: '',
          contactPhone: '',
          ext1: '',
          id: new Date().getTime(),
        },
      ],
      mdmCustomerDockingVos: [
        {
          orgCode: '',
          positionCode: '',
          // channel: '',
          ext1: '', // 公司名称
          ext2: '', // 公司编码
          contactPhone: '',
          fullName: '',
          id: new Date().getTime(),
        },
      ], // 扩展信息
      // mdmCustomerBillVos: [
      //   {
      //     billCompany: '', // 开票单位
      //     taxNumber: '', // 税号
      //     legal: '', // 法人
      //     depositBank: '', // 开户银行
      //     bankAccount: '', // 银行账号
      //     billMain: '', // 是否是默认开票信息，1是0否
      //     id: new Date().getTime(),
      //   },
      // ],
      // 开票信息
      // mdmCustomerSaleAreaVosItem: {
      //   areaOne: '',
      //   areaTwo: '',
      //   areaThree: '',
      // },
      // mdmCustomerContactVosItem: {
      //   contactMain: '',
      //   contactName: '',
      //   contactPhone: '',
      // },
      mdmCustomerDockingVosItem: {
        orgCode: '',
        positionCode: '',
        // channel: '',
        ext1: '', // 公司编码
        ext2: '', // 公司名称
        contactPhone: '',
        fullName: '',
      },
      // mdmCustomerBillVosItem: {
      //   billCompany: '', // 开票单位
      //   taxNumber: '', // 税号
      //   legal: '', // 法人
      //   depositBank: '', // 开户银行
      //   bankAccount: '', // 银行账号
      //   billMain: '', // 是否是默认开票信息，1是0否
      // },
      showData: null,
      productReqs: [
        {
          productCode: '',
          productName: '',
          saleArea: '',
          saleChannel: '',
          salesTask: '',
          firstReturn: '',
          employeeNum: '',
          id: new Date().getTime(),
        },
      ],
      productReqsItem: {
        productCode: '',
        productName: '',
        saleArea: '',
        saleChannel: '',
        salesTask: '',
        firstReturn: '',
        employeeNum: '',
      },
      brandReqs: [{
        brandName: '',
        sales: '',
      }],
      privateWarehouse: {
        warehouseNum: 0,
        warehouseSquare: 0,
      },
      selfOperatedStores: {
        storeNum: 0,
        storeSquare: 0,
      },
    };
  },
  async created() {
    if (this.noButton) {
      this.buttons = {
        submit: false,
        close: false,
        loading: false,
        submitAudit: false,
      };
    }
    // 获取规则
    await this.getFormRule('customer_information_add', 'CRM20201127000000116');

    // 省、市、区
    const provinceName = this.getRule('provinceCode');
    const cityName = this.getRule('cityCode');
    const districtName = this.getRule('districtCode');
    provinceName.restful = '/mdm/mdmRegionController/selectStartWithProvince';
    provinceName.optionsKey = { label: 'regionName', value: 'regionCode' };
    provinceName.props = {
      ...provinceName.props,
      // filterable: true,
      // remote: true,
      remoteParams: 'regionName',
    };

    cityName.optionsKey = { label: 'regionName', value: 'regionCode' };
    districtName.optionsKey = { label: 'regionName', value: 'regionCode' };

    provinceName.restfulParams = {
      regionLevel: '1',
    };
    provinceName.on = {
      change: (val) => {
        console.log(val);
        if (val) {
          this.disabled(false, ['cityCode']);
        } else {
          this.disabled(true, ['cityCode', 'districtCode']);
        }
        cityName.value = '';
        districtName.value = '';
        if (!val) {
          cityName.restful = '';
          districtName.restful = '';
          return;
        }
        cityName.restfulParams = {
          parentCode: val,
        };
        cityName.restful = '/mdm/mdmRegionController/selectStartWithProvince';
        cityName.props = {
          ...cityName.props,
          filterable: true,
          remote: true,
          remoteParams: 'regionName',
        };
        cityName.refresh = true;
      },
    };
    cityName.on = {
      change: (val) => {
        if (val) {
          this.disabled(false, ['districtCode']);
        } else {
          this.disabled(true, ['districtCode']);
        }
        districtName.value = '';
        if (!val) {
          districtName.restful = '';
          return;
        }
        districtName.restfulParams = {
          parentCode: val,
        };
        districtName.restful = '/mdm/mdmRegionController/selectStartWithProvince';
        districtName.props = {
          ...districtName.props,
          filterable: true,
          remote: true,
          remoteParams: 'regionName',
        };
        districtName.refresh = true;
      },
    };

    // if (this.formConfig.code === 'add') {
    //   this.hiddenFields(true, ['title7', 'customerUserList']);
    // }

    // this.showData = await this.getDic();
    // this.showData.forEach((item) => {
    //   if (item.dictCode === 'sales_area') {
    //     if (item.dictValue === 'N') {
    //       this.hiddenFields(true, ['title2', 'mdmCustomerSaleAreaVos']);
    //     }
    //   }
    //   if (item.dictCode === 'contact_information') {
    //     if (item.dictValue === 'N') {
    //       this.hiddenFields(true, ['title3', 'mdmCustomerContactVos']);
    //     }
    //   }
    //   if (item.dictCode === 'extended_information') {
    //     if (item.dictValue === 'N') {
    //       this.hiddenFields(true, ['title4', 'mdmCustomerDockingVos']);
    //     }
    //   }
    //   if (item.dictCode === 'customer_bill') {
    //     if (item.dictValue === 'N') {
    //       this.hiddenFields(true, ['title5', 'mdmCustomerBillVos']);
    //     }
    //   }
    //   if (item.dictCode === 'extended_information_product') {
    //     if (item.dictValue === 'N') {
    //       const mdmCustomerDockingVos = this.getRule('mdmCustomerDockingVos');
    //       mdmCustomerDockingVos.props.showProduct = false;
    //     }
    //   }
    // });
    // if (this.formConfig.buttonCode === 'edit') {
    //   this.disabled(true, ['customerCode']);
    // }
    // const registeredAddress = this.getRule('registeredAddress');
    // registeredAddress.on = {
    //   ...registeredAddress.on,
    //   change: (e) => {
    //     registeredAddress.props = {
    //       ...registeredAddress.props,
    //       lng: (e.lng).toString(),
    //       lat: (e.lat).toString(),
    //     };
    //     this.setValue({
    //       longitude: e.lng,
    //       latitude: e.lat,
    //     });
    //   },
    // };

    // 销售区域
    // const mdmCustomerSaleAreaVos = this.getRule('mdmCustomerSaleAreaVos');
    // mdmCustomerSaleAreaVos.on = {
    //   del: (index) => {
    //     this.mdmCustomerSaleAreaVos.splice(index, 1);
    //   },
    // };
    // 销售区域显示添加按钮
    // const title2 = this.getRule('title2');
    // if (this.formConfig.code !== 'view') {
    //   title2.props.showAdd = true;
    // }
    // mdmCustomerSaleAreaVos.props = {
    //   ...mdmCustomerSaleAreaVos.props,
    //   saleAreaData: this.mdmCustomerSaleAreaVos,
    // };
    // title2.on = {
    //   add: () => {
    //     this.mdmCustomerSaleAreaVos.push({
    //       ...this.mdmCustomerSaleAreaVosItem,
    //       id: new Date().getTime(),
    //     });
    //     mdmCustomerSaleAreaVos.props = {
    //       ...mdmCustomerSaleAreaVos.props,
    //       saleAreaData: this.mdmCustomerSaleAreaVos,
    //     };
    //   },
    // };
    // 联系人
    // const mdmCustomerContactVos = this.getRule('mdmCustomerContactVos');
    // mdmCustomerContactVos.on = {
    //   del: (index) => {
    //     console.log(index);
    //     this.mdmCustomerContactVos.splice(index, 1);
    //   },
    // };
    // // 联系人信息添加按钮
    // const title3 = this.getRule('title3');
    // if (this.formConfig.code !== 'view') {
    //   title3.props.showAdd = true;
    // }
    // mdmCustomerContactVos.props = {
    //   ...mdmCustomerContactVos.props,
    //   contactInfoData: this.mdmCustomerContactVos,
    // };
    // title3.on = {
    //   add: () => {
    //     this.mdmCustomerContactVos.push({
    //       ...this.mdmCustomerContactVosItem,
    //       id: new Date().getTime(),
    //     });
    //     mdmCustomerContactVos.props = {
    //       ...mdmCustomerContactVos.props,
    //       contactInfoData: this.mdmCustomerContactVos,
    //     };
    //   },
    // };
    // 新增字段
    // 注册资金（万元）
    const registerAmount = this.getRule('registerAmount');
    registerAmount.props = {
      ...registerAmount.props,
      min: 0,
      controls: false,
    };
    // 与其他品牌总营业额（万元/年）
    const brandSales = this.getRule('brandSales');
    brandSales.props = {
      ...brandSales.props,
      min: 0,
      controls: false,
    };
    // 流动资金（万元）
    const workingAmount = this.getRule('workingAmount');
    workingAmount.props = {
      ...workingAmount.props,
      min: 0,
      controls: false,
      precision: 0,
    };
    // 终端网点数量
    const onlineNum = this.getRule('onlineNum');
    onlineNum.props = {
      ...onlineNum.props,
      min: 0,
      controls: false,
      precision: 0,
    };
    // 团购单位（家）
    const groupNum = this.getRule('groupNum');
    groupNum.props = {
      ...groupNum.props,
      min: 0,
      controls: false,
      precision: 0,
    };
    // 配送车辆（辆）
    const vehiclesNum = this.getRule('vehiclesNum');
    vehiclesNum.props = {
      ...vehiclesNum.props,
      min: 0,
      controls: false,
      precision: 0,
    };
    // 员工总人数（位）
    const employeeNum = this.getRule('employeeNum');
    employeeNum.props = {
      ...employeeNum.props,
      min: 0,
      controls: false,
      precision: 0,
    };
    // 管理人员（位）
    const administratorNum = this.getRule('administratorNum');
    administratorNum.props = {
      ...administratorNum.props,
      min: 0,
      controls: false,
      precision: 0,
    };
    // 业务人员（位）
    const salesmanNum = this.getRule('salesmanNum');
    salesmanNum.props = {
      ...salesmanNum.props,
      min: 0,
      controls: false,
      precision: 0,
    };
    // 成立时间
    const establishedTime = this.getRule('establishedTime');
    establishedTime.props = {
      ...establishedTime.props,
      type: 'year',
    };
    // 实际操盘人（电话）
    const possessorMobile = this.getRule('possessorMobile');
    possessorMobile.props = {
      ...possessorMobile.props,
      maxlength: 11,
    };
    possessorMobile.validate = [
      ...possessorMobile.validate,
      { pattern: /^1[3-9]\d{9}$/, message: '请输入正确手机号', trigger: 'change' },
    ];
    // 西凤酒合作概況
    const productReqs = this.getRule('productReqs');
    productReqs.on = {
      del: (index) => {
        this.productReqs.splice(index, 1);
      },
    };
    // 西凤酒合作概況添加按钮
    const title100 = this.getRule('title100');
    if (this.formConfig.code !== 'view') {
      title100.props.showAdd = true;
    }
    productReqs.props = {
      ...productReqs.props,
      overviewOfCooperation: this.productReqs,
    };
    title100.on = {
      add: () => {
        this.productReqs.push({
          ...this.productReqsItem,
          id: new Date().getTime(),
        });
        productReqs.props = {
          ...productReqs.props,
          overviewOfCooperation: this.productReqs,
        };
      },
    };
    // 客户合作品牌方
    const brandReqs = this.getRule('brandReqs');
    brandReqs.on = {
      del: (data) => {
        this.brandReqs = data;
      },
      add: (data) => {
        this.brandReqs = data;
      },
    };
    brandReqs.props = {
      ...brandReqs.props,
      brandReqs: this.brandReqs,
    };
    // 自有仓库
    const privateWarehouse = this.getRule('privateWarehouse');
    privateWarehouse.on = {
      changeNum: (data) => {
        this.selfOperatedStores.warehouseNum = data || 0;
      },
      changeSquare: (data) => {
        this.selfOperatedStores.warehouseSquare = data || 0;
      },
    };
    privateWarehouse.props = {
      ...privateWarehouse.props,
      warehouse: this.privateWarehouse,
    };
    // 自营店铺
    const selfOperatedStores = this.getRule('selfOperatedStores');
    selfOperatedStores.on = {
      changeNum: (data) => {
        this.selfOperatedStores.storeNum = data || 0;
      },
      changeSquare: (data) => {
        this.selfOperatedStores.storeSquare = data || 0;
      },
    };
    selfOperatedStores.props = {
      ...selfOperatedStores.props,
      store: this.selfOperatedStores,
    };
    // 负责人信息
    const mdmCustomerDockingVos = this.getRule('mdmCustomerDockingVos');
    mdmCustomerDockingVos.on = {
      del: (index) => {
        this.mdmCustomerDockingVos.splice(index, 1);
      },
    };

    // 负责人添加按钮
    const title90 = this.getRule('title90');
    if (this.formConfig.code !== 'view') {
      title90.props.showAdd = true;
    }
    mdmCustomerDockingVos.props = {
      ...mdmCustomerDockingVos.props,
      extendInfoData: this.mdmCustomerDockingVos,
    };
    title90.on = {
      add: () => {
        this.mdmCustomerDockingVos.push({
          ...this.mdmCustomerDockingVosItem,
          id: new Date().getTime(),
        });
        mdmCustomerDockingVos.props = {
          ...mdmCustomerDockingVos.props,
          extendInfoData: this.mdmCustomerDockingVos,
        };
      },
    };

    // 开票信息
    // const mdmCustomerBillVos = this.getRule('mdmCustomerBillVos');
    // mdmCustomerBillVos.on = {
    //   del: (index) => {
    //     this.mdmCustomerBillVos.splice(index, 1);
    //   },
    // };

    // const title5 = this.getRule('title5');
    // if (this.formConfig.code !== 'view') {
    //   title5.props.showAdd = true;
    // }
    // mdmCustomerBillVos.props = {
    //   ...mdmCustomerBillVos.props,
    //   invoiceInfoData: this.mdmCustomerBillVos,
    // };
    // title5.on = {
    //   add: () => {
    //     this.mdmCustomerBillVos.push({
    //       ...this.mdmCustomerBillVosItem,
    //       id: new Date().getTime(),
    //     });
    //     mdmCustomerBillVos.props = {
    //       ...mdmCustomerBillVos.props,
    //       invoiceInfoData: this.mdmCustomerBillVos,
    //     };
    //   },
    // };
    // 所属组织名称
    // const orgName = this.getRule('orgCode');
    // orgName.props = {
    //   ...orgName.props,
    //   restful: '/mdm/baseTreeController/orgTree',
    //   restfulParams: {
    //     enableStatus: '009',
    //   },
    //   headers: { functionCode: 'select-org' },
    //   props: {
    //     value: 'code', // 唯一标识
    //     key: 'code',
    //     label: 'name', // 标签显示
    //     children: 'children', // 子级
    //   },
    // };
    // 客户组织
    // const customerOrgName = this.getRule('customerOrgCode');
    // customerOrgName.restful = '/mdm/baseTreeController/customerOrgTree';
    // customerOrgName.headers = { functionCode: 'select-customer-org' };
    // customerOrgName.restfulParams = {
    //   enableStatus: '009',
    // };
    // customerOrgName.props = {
    //   ...customerOrgName.props,
    //   props: {
    //     parent: 'parentCode', // 父级唯一标识
    //     value: 'code', // 唯一标识
    //     label: 'name', // 标签显示
    //     children: 'children', // 子级
    //   },
    // };

    // const customerCode = this.getRule('customerCode');
    // customerCode.validate = [
    //   ...customerCode.validate,
    //   {
    //     trigger: 'blur',
    //     pattern: '^[a-zA-Z0-9]*$',
    //     message: '仅能输入英文和数字的组合',
    //   },
    // ];
    // const customerContact = this.getRule('customerContact');
    // customerContact.validate = [
    //   {
    //     trigger: 'blur',
    //     pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
    //     message: '请输入正确的电话号码',
    //   },
    // ];
    // const telephone = this.getRule('telephone');

    // telephone.validate = [
    //   {
    //     trigger: 'blur',
    //     pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
    //     message: '请输入正确的电话号码',
    //   },
    // ];

    const len = Object.keys(this.formConfig).length;
    this.reload(this.rule);
    if (this.formConfig.code === 'add') {
      this.disabled(true, ['cityCode', 'districtCode']);
    }
    if (len === 0 || (len > 0 && this.formConfig.functionLevel === '0')) {
      this.hiddenFields(true, ['parentCode']);
    }
    if (this.formConfig.row && this.formConfig.row.id) {
      // console.log(this.formConfig);
      if (this.formConfig.code === 'edit') {
        const params = {
          positionCode: JSON.parse(localStorage.getItem('userInfo')).positionCode,
        };
        request.post(`/mdm/mdmRoleController/getRoleByPositionCode?positionCode=${params.positionCode}`).then((res) => {
          if (res.success) {
            const admin = (res.result || []).includes('ADMIN');
            const title9011 = this.getRule('title90');
            if (!admin) {
              title9011.props.showAdd = false;
              this.disabled(true, ['mdmCustomerDockingVos']);
            } else {
              title9011.props.showAdd = true;
              this.disabled(false, ['mdmCustomerDockingVos']);
            }
          }
        });
      }
      const data = {
        id: this.formConfig.row.id,
        customerCode: this.formConfig.customerCode,
      };
      request.get('/mdm/mdmCustomerMsgExtController/queryDetail', data).then((e) => {
        const { result } = e;
        if (!result) return;
        cityName.restfulParams = {
          parentCode: result && result.provinceCode,
        };
        if (result && result.provinceCode) {
          cityName.restful = '/mdm/mdmRegionController/selectStartWithProvince';
          cityName.props = {
            ...cityName.props,
            filterable: true,
            remote: true,
            remoteParams: 'regionName',
          };
          cityName.refresh = true;
        }
        districtName.restfulParams = {
          parentCode: result && result.cityCode,
        };
        if (result && result.cityCode) {
          districtName.restful = '/mdm/mdmRegionController/selectStartWithProvince';
          districtName.props = {
            ...districtName.props,
            filterable: true,
            remote: true,
            remoteParams: 'regionName',
          };
          districtName.refresh = true;
        }

        // result.form_list = [];
        // result.form_list.push({
        //   title: '销售区域',
        //   type: 'saleArea',
        //   list: [],
        // });
        // this.mdmCustomerSaleAreaVos = !result.mdmCustomerSaleAreaVos || result.mdmCustomerSaleAreaVos.length === 0
        //   ? [
        //     {
        //       areaOne: '',
        //       areaTwo: '',
        //       areaThree: '',
        //       id: new Date().getTime(),
        //     },
        //   ]
        //   : result.mdmCustomerSaleAreaVos;
        // this.mdmCustomerContactVos = !result.mdmCustomerContactVos || result.mdmCustomerContactVos.length === 0
        //   ? [
        //     {
        //       contactMain: '',
        //       contactName: '',
        //       contactPhone: '',
        //       id: new Date().getTime(),
        //     },
        //   ]
        //   : result.mdmCustomerContactVos;
        this.mdmCustomerDockingVos = !result.mdmCustomerDockingVos || result.mdmCustomerDockingVos.length === 0
          ? [
            {
              orgCode: '',
              positionCode: '',
              // channel: '',
              // saleCompany: '',
              ext1: '',
              ext2: '',
              contactPhone: '',
              fullName: '',
              id: new Date().getTime(),
            },
          ]
          : result.mdmCustomerDockingVos;
        this.productReqs = !result.productResps || result.productResps.length === 0
          ? [
            {
              productCode: '',
              productName: '',
              saleArea: '',
              saleChannel: '',
              salesTask: '',
              firstReturn: '',
              employeeNum: '',
              id: new Date().getTime(),
            },
          ]
          : result.productResps;
        this.brandReqs = !result.brandResps || result.brandResps.length === 0
          ? [
            {
              brandName: '',
              sales: '',
            },
          ]
          : result.brandResps;
        this.selfOperatedStores = {
          storeNum: result.storeNum || 0,
          storeSquare: result.storeSquare || 0,
        };
        this.privateWarehouse = {
          warehouseNum: result.warehouseNum || 0,
          warehouseSquare: result.warehouseSquare || 0,
        };

        mdmCustomerDockingVos.props = {
          ...mdmCustomerDockingVos.props,
          extendInfoData: this.mdmCustomerDockingVos,
        };
        productReqs.props = {
          ...productReqs.props,
          overviewOfCooperation: this.productReqs,
        };
        brandReqs.props = {
          ...brandReqs.props,
          brandReqs: this.brandReqs,
        };
        selfOperatedStores.props = {
          ...selfOperatedStores.props,
          store: this.selfOperatedStores,
        };
        privateWarehouse.props = {
          ...privateWarehouse.props,
          warehouse: this.privateWarehouse,
        };
        result.corporateIdentityImageList = result.corporateIdentityImageList || [];
        this.setValue(result);

        // 财务信息
        const {
          financeContact, billPhone, billType, bank, bankAccount, accountName, bankAddress, rate, takerPhone, takerMailCode, ticketAddress,
        } = result.customerFinanceVo || {};
        // 联系人
        const { contactName, contactPhone, ext1 } = result.mdmCustomerContactVos[0] || {};
        const {
          beEntrust,
          beEntrustPhone,
          entrust,
          entrustPhone,
        } = result.customerDeliveryVo || {};
        // 发货信息
        this.setValue({
          beEntrust,
          beEntrustPhone,
          entrust,
          entrustPhone,
          financeContact,
          billPhone,
          billType,
          bank,
          bankAccount,
          accountName,
          bankAddress,
          rate,
          takerPhone,
          takerMailCode,
          ticketAddress,
          contactName,
          contactPhone,
          ext1, // 电子邮件
          contactMain: 1, // 是否主要联系人
        });
        // this.mdmCustomerBillVos = !result.mdmCustomerBillVos || result.mdmCustomerBillVos.length === 0
        //   ? [
        //     {
        //       billCompany: '', // 开票单位
        //       taxNumber: '', // 税号
        //       legal: '', // 法人
        //       depositBank: '', // 开户银行
        //       bankAccount: '', // 银行账号
        //       billMain: '', // 是否是默认开票信息，1是0否
        //       id: new Date().getTime(),
        //     },
        //   ]
        //   : result.mdmCustomerBillVos;
        // mdmCustomerSaleAreaVos.props = {
        //   ...mdmCustomerSaleAreaVos.props,
        //   saleAreaData: this.mdmCustomerSaleAreaVos,
        // };
        // mdmCustomerContactVos.props = {
        //   ...mdmCustomerContactVos.props,
        //   contactInfoData: this.mdmCustomerContactVos,
        // };

        // mdmCustomerBillVos.props = {
        //   ...mdmCustomerBillVos.props,
        //   invoiceInfoData: this.mdmCustomerBillVos,
        // };
        // result.certificateImageList = result.certificateImageList || [];

        // const registeredAddress = this.getRule('registeredAddress');
        // registeredAddress.props = {
        //   ...registeredAddress.props,
        //   lat: result.latitude,
        //   lng: result.longitude,
        // };
        if (!result.provinceCode) {
          this.disabled(true, ['cityCode', 'districtCode']);
        }
        if (!result.cityCode) {
          this.disabled(true, ['districtCode']);
        }
        if (result.actApproveStatus === '3') {
          this.disabled(true, ['customerName', 'customerTax']);
        }
      });
    }
  },
  methods: {

    /**
     * 表单渲染完成后回调
     */
    formComplete() {

    },
    async getDic() {
      const resData = await request.post('/mdm/mdmdictdata/list', {
        dictTypeCode: 'customer_add_section',
      });
      return resData.result;
    },
    // 提交
    submit() {
      const formData = this.getFormData();
      // this.showData.forEach((item) => {
      // if (item.dictCode === 'sales_area' && item.dictValue === 'Y') {
      //   const mdmCustomerSaleAreaVos = this.mdmCustomerSaleAreaVos.length > 0
      //     ? this.fApi.method('mdmCustomerSaleAreaVos', 'getFormData')()
      //     : [];
      //   formData.mdmCustomerSaleAreaVos = mdmCustomerSaleAreaVos;
      // }
      // if (item.dictCode === 'contact_information' && item.dictValue === 'Y') {
      //   const mdmCustomerContactVos = this.mdmCustomerContactVos.length > 0
      //     ? this.fApi.method('mdmCustomerContactVos', 'getFormData')()
      //     : [];
      //   formData.mdmCustomerContactVos = mdmCustomerContactVos;
      // }
      //   if (item.dictCode === 'extended_information' && item.dictValue === 'Y') {
      //     const mdmCustomerDockingVos = this.mdmCustomerDockingVos.length > 0
      //       ? this.fApi.method('mdmCustomerDockingVos', 'getFormData')()
      //       : [];

      //     formData.mdmCustomerDockingVos = mdmCustomerDockingVos;
      //   }
      //   if (item.dictCode === 'customer_bill' && item.dictValue === 'Y') {
      //     const mdmCustomerBillVos = this.mdmCustomerBillVos.length > 0
      //       ? this.fApi.method('mdmCustomerBillVos', 'getFormData')()
      //       : [];

      //     formData.mdmCustomerBillVos = mdmCustomerBillVos;
      //   }
      // });
      if (!this.selfOperatedStores.storeNum && !this.selfOperatedStores.storeSquare) {
        this.$message.error('请填写自营店铺数据！');
        return;
      }
      formData.storeNum = this.selfOperatedStores.storeNum;
      formData.storeSquare = this.selfOperatedStores.storeSquare;
      if (!this.privateWarehouse.warehouseNum && !this.privateWarehouse.warehouseSquare) {
        this.$message.error('请填写自有仓库数据！');
        return;
      }
      formData.warehouseNum = this.privateWarehouse.warehouseNum;
      formData.warehouseSquare = this.privateWarehouse.warehouseSquare;
      const isBrand = this.brandReqs.filter((v) => !v.brandName);
      if (isBrand.length > 0) {
        this.$message.error('请填写客户合作品牌方数据！');
        return;
      }
      formData.brandReqs = this.brandReqs;
      const productReqs = this.productReqs.length > 0
        ? this.fApi.method('productReqs', 'getFormData')()
        : [];
      if (this.fApi.method('productReqs', 'getFormData')() === 'noCheck') {
        return;
      }
      formData.productReqs = productReqs;
      const mdmCustomerDockingVos = this.mdmCustomerDockingVos.length > 0
        ? this.fApi.method('mdmCustomerDockingVos', 'getFormData')()
        : [];
      if (this.fApi.method('mdmCustomerDockingVos', 'getFormData')() === 'noCheck') {
        return;
      }
      formData.mdmCustomerDockingVos = mdmCustomerDockingVos;
      console.log('/mdm/mdmCustomerMsgExtController/queryDetail', mdmCustomerDockingVos);
      // 发货信息
      formData.customerDeliveryVo = {
        beEntrust: formData.beEntrust,
        beEntrustPhone: formData.beEntrustPhone,
        entrust: formData.entrust,
        entrustPhone: formData.entrustPhone,
      };

      // 财务信息
      const {
        financeContact, billPhone, billType, bank, bankAccount, accountName, bankAddress, rate, takerPhone, takerMailCode, ticketAddress,
      } = formData;

      formData.customerFinanceVo = {
        financeContact, billPhone, billType, bank, bankAccount, accountName, bankAddress, rate, takerPhone, takerMailCode, ticketAddress,
      };

      // 联系人
      formData.mdmCustomerContactVos = [
        {
          contactName: formData.contactName,
          contactPhone: formData.contactPhone,
          ext1: formData.ext1, // 电子邮件
          contactMain: 1, // 是否主要联系人
        },
      ];
      if (formData) {
        const url = '/mdm/mdmCustomerMsgExtController/saveOrUpdate';
        let params = formData;

        if (this.formConfig && this.formConfig.code === 'add') {
          formData.approvalBusinessCode = 'mdm_customer_default_curd';
        }
        if (this.formConfig && this.formConfig.code === 'edit') {
          params = Object.assign(this.formConfig, formData);
        }
        delete params.form_list;
        delete params.createDate;
        delete params.createDateAll;
        delete params.createDateSecond;
        delete params.createCode;
        delete params.createName;
        delete params.createOrgCode;
        delete params.createOrgName;
        delete params.createPosCode;
        delete params.createPosName;
        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
            // this.$emit('resetTree');
          }
        });
      }
    },
  },
};
</script>
