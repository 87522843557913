<script>
import Form from '../../../../../../components/form';

export default {
  extends: Form,
  props: {
    saleAreaIteData: Object,
    fieldDisabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      buttons: {
        submit: false,
        close: false,
      },
      rule: [
        {
          title: '一级区域',
          type: 'select',
          field: 'areaOne',
          options: [],
          value: this.saleAreaIteData.areaOne,
          restful: '/mdm/mdmOrgController/select',
          restfulParams: {
          },
          optionsKey: {
            label: 'orgName',
            value: 'orgCode',
          },
          on: {
            change: (e) => {
              if (e) {
                this.disabled(false, ['areaTwo']);
              } else {
                this.disabled(true, ['areaTwo', 'areaThree']);
              }
              this.setValue({
                areaTwo: '',
                areaThree: '',
              });
            },
          },
          props: {
            disabled: this.fieldDisabled,
            filterable: true,
            remote: true,
            clearable: true,
            remoteParams: 'orgName',
          },
          col: {
            md: {
              span: 8,
            },
          },
        },
        {
          title: '二级区域',
          type: 'select',
          field: 'areaTwo',
          value: this.saleAreaIteData.areaTwo,
          restful: '/mdm/mdmOrgController/select',
          restfulParamsGetValue: {
            parentCode: 'areaOne',
          },
          optionsKey: {
            label: 'orgName',
            value: 'orgCode',
          },
          on: {
            change: (e) => {
              if (e) {
                this.disabled(false, ['areaThree']);
              } else {
                this.disabled(true, ['areaThree']);
              }
              this.setValue({
                areaThree: '',
              });
            },
          },
          refresh: true,
          props: {
            filterable: true,
            remote: true,
            clearable: true,
            disabled: !this.saleAreaIteData.areaTwo || this.fieldDisabled,
            remoteParams: 'orgName',
          },
          options: [],
          col: {
            md: {
              span: 8,
            },
          },
        },
        {
          title: '三级区域',
          type: 'select',
          field: 'areaThree',
          value: this.saleAreaIteData.areaThree,
          restful: '/mdm/mdmOrgController/select',
          restfulParamsGetValue: {
            parentCode: 'areaTwo',
          },
          optionsKey: {
            label: 'orgName',
            value: 'orgCode',
          },
          refresh: true,
          props: {
            filterable: true,
            remote: true,
            clearable: true,
            disabled: !this.saleAreaIteData.areaThree || this.fieldDisabled,
            remoteParams: 'orgName',
          },
          col: {
            md: {
              span: 8,
            },
          },
        },

      ],
    };
  },
  mounted() {
    if (Object.keys(this.saleAreaIteData)) {
      this.setValue(this.saleAreaIteData);
    }
  },
  methods: {},
};
</script>
