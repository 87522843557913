<script>
import Form, { formCreate } from '@/found/components/form';
import { log } from 'mathjs';
// import customerInfoProduct from './product.vue';

// formCreate.component('customerInfoProduct', customerInfoProduct);

export default {
  components: {
    formCreate: formCreate.$form(),
  },
  extends: Form,
  props: {
    extendInfoData: Object,
    fieldDisabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
    showProduct: {
      type: Boolean,
      default() {
        return true;
      },
    },
  },
  data() {
    return {
      buttons: {
        submit: false,
        close: false,
      },
      rule: [
        {
          title: '职位',
          type: 'select',
          field: 'positionCode',
          required: true,
          options: [],
          value: this.extendInfoData.positionCode,
          restful: '/mdm/mdmPositionController/positionSelectList',
          headers: { functionCode: 'select-position' },
          optionsKey: {
            label: 'unionName',
            value: 'positionCode',
          },
          on: {
            change: (e) => {
              const thisData = this.getRule('positionCode').options.find((item) => item.positionCode === e);
              this.disabled(false, ['ext2']);
              if (thisData) {
                this.setValue({
                  fullName: thisData.fullName,
                  contactPhone: thisData.userPhone,
                  orgCode: thisData.orgCode,
                });
              } else {
                this.setValue({
                  fullName: '',
                  contactPhone: '',
                  orgCode: '',
                  ext1: '',
                });
                this.disabled(true, ['ext2']);
              }
              this.setValue({
                ext1: null,
                ext2: null,
              });
              this.getRule('ext2').options = [];
            },
          },
          props: {
            filterable: true,
            disabled: this.fieldDisabled,
            remote: true,
            clearable: true,
            remoteParams: 'unionName',
          },
          col: {
            md: {
              span: 8,
            },
          },
        },
        {
          title: '所属组织',
          type: 'select',
          field: 'orgCode',
          options: [],
          value: this.extendInfoData.orgCode,
          restful: '/mdm/mdmOrgController/select',
          optionsKey: {
            label: 'orgName',
            value: 'orgCode',
          },
          on: {
            change: (e) => {
              console.log(e, this.extendInfoData);
            },
          },
          props: {
            disabled: true,
            filterable: true,
            remote: true,
            clearable: true,
            remoteParams: 'orgName',
          },
          col: {
            md: {
              span: 8,
            },
          },
        },

        {
          title: '用户名',
          type: 'input',
          field: 'fullName',
          value: this.extendInfoData.fullName,
          props: {
            disabled: true,
          },
          col: {
            md: {
              span: 8,
            },
          },
        },
        {
          title: '联系方式',
          type: 'input',
          field: 'contactPhone',
          value: this.extendInfoData.contactPhone,
          props: {
            disabled: true,
          },
          col: {
            md: {
              span: 8,
            },
          },
        },
        // {
        //   title: '选择渠道',
        //   type: 'select',
        //   field: 'channel',
        //   options: [],
        //   value: this.extendInfoData.channel,
        //   dictCode: 'channel',
        //   on: {
        //     change: (e) => {
        //       console.log(e);
        //     },
        //   },
        //   props: {
        //     filterable: true,
        //     disabled: this.fieldDisabled,
        //   },
        //   col: {
        //     md: {
        //       span: 8,
        //     },
        //   },
        // },
        {
          title: '分公司',
          type: 'select',
          field: 'ext2',
          refresh: true,
          options: [],
          value: this.extendInfoData.ext2,
          restful: '/mdm/eRPMaintainOrgController/getBranchOfficeByOrgCode',
          restfulParamsGetValue: {
            orgCode: 'orgCode',
          },
          optionsKey: {
            label: 'orgName',
            value: 'orgCode',
          },
          on: {
            change: (e) => {
              const chooseData = this.getRule('ext2').options.find((item) => item.orgCode === e);
              if (e) {
                console.log('进入了改变方法');
                this.setValue({
                  ext2: e,
                  ext1: chooseData.orgName,
                });
              }
            },
          },

          props: {
            filterable: true,
            disabled: this.fieldDisabled,
          },
          col: {
            md: {
              span: 8,
            },
          },
        },
        {
          title: '分公司名称',
          type: 'input',
          field: 'ext1',
          value: this.extendInfoData.ext1,
          col: {
            md: {
              span: 8,
            },
          },
        },
        // {
        //   title: '',
        //   type: 'customerInfoProduct',
        //   field: 'mdmCustomerSupplyDetailVos',
        //   value: this.extendInfoData.mdmCustomerSupplyDetailVos,
        //   props: {
        //     disabled: this.fieldDisabled,
        //   },
        //   col: {
        //     md: {
        //       span: 24,
        //     },
        //   },
        // },
      ],
    };
  },
  mounted() {
    this.disabled(true, ['ext2']);
    this.hiddenFields(true, 'ext1');
    if (!this.showProduct) {
      this.hiddenFields(true, 'mdmCustomerSupplyDetailVos');
    }
  },
  methods: {},
};
</script>
