<script>
import Form, { formCreate } from '@/found/components/form';

export default {
  components: {
    formCreate: formCreate.$form(),
  },
  extends: Form,
  props: {
    overviewOfCooperation: Object,
    fieldDisabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  watch: {
    overviewOfCooperation(newVal, oldVal) {
      this.setValue(newVal);
    },
  },
  data() {
    return {
      buttons: {
        submit: false,
        close: false,
      },
      rule: [
        {
          title: '计划合作产品',
          type: 'select',
          field: 'productCode',
          required: true,
          options: [],
          value: this.overviewOfCooperation.productCode,
          restful: '/mdm/mdmMaterialExtController/pageList',
          headers: { functionCode: 'select-product' },
          optionsKey: {
            label: 'materialName',
            value: 'materialCode',
          },
          on: {
            change: (e) => {
              const thisData = this.getRule('productCode').options.find((item) => item.materialCode === e);
              if (thisData) {
                this.setValue({
                  productName: thisData.materialName,
                });
              } else {
                this.setValue({
                  productName: '',
                });
              }
            },
            optionCallback: (options, value) => {
              let isErrer = null;
              isErrer = options.find((v) => v.materialCode === value);
              if (!isErrer) {
                options.push(
                  {
                    label: this.overviewOfCooperation.productName,
                    value,
                    materialName: this.overviewOfCooperation.productName,
                    materialCode: value,
                  },
                );
              }
            },
          },
          props: {
            filterable: true,
            disabled: this.fieldDisabled,
            remote: true,
            clearable: true,
            remoteParams: 'materialName',
          },
          col: {
            md: {
              span: 8,
            },
          },
        },
        {
          title: '计划合作产品名称',
          type: 'input',
          field: 'productName',
          value: this.overviewOfCooperation.productName,
          props: {
            disabled: true,
          },
          col: {
            md: {
              span: 8,
            },
          },
        },
        {
          title: '产品销售区域',
          type: 'input',
          field: 'saleArea',
          required: true,
          value: this.overviewOfCooperation.saleArea,
          props: {
            disabled: this.fieldDisabled,
          },
          col: {
            md: {
              span: 8,
            },
          },
        },
        {
          title: '选择渠道',
          type: 'select',
          field: 'saleChannel',
          required: true,
          options: [],
          value: this.overviewOfCooperation.saleChannel,
          dictCode: 'channel',
          props: {
            filterable: true,
            disabled: this.fieldDisabled,
          },
          col: {
            md: {
              span: 8,
            },
          },
        },
        {
          title: '年度销售任务（万元）',
          type: 'inputNumber',
          field: 'salesTask',
          required: true,
          value: this.overviewOfCooperation.salesTask,
          props: {
            disabled: this.fieldDisabled,
            min: 0,
            controls: false,
          },
          col: {
            md: {
              span: 8,
            },
          },
        },
        {
          title: '首单回款（万元）',
          type: 'inputNumber',
          field: 'firstReturn',
          required: true,
          value: this.overviewOfCooperation.firstReturn,
          props: {
            disabled: this.fieldDisabled,
            min: 0,
            controls: false,
          },
          col: {
            md: {
              span: 8,
            },
          },
        },
        {
          title: '专项运作团队（人）',
          type: 'inputNumber',
          field: 'employeeNum',
          required: true,
          value: this.overviewOfCooperation.employeeNum,
          props: {
            disabled: this.fieldDisabled,
            min: 0,
            controls: false,
            precision: 0,
          },
          col: {
            md: {
              span: 8,
            },
          },
        },
      ],
    };
  },
  mounted() {
    this.hiddenFields(true, 'productName');
  },
  methods: {},
};
</script>
