<script>
import Form from '../../../../../../components/form';

export default {
  extends: Form,
  props: {
    invoiceInfoItemData: Object,
    fieldDisabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      buttons: {
        submit: false,
        close: false,
      },
      rule: [
        {
          title: '开票单位',
          type: 'input',
          field: 'billCompany',
          props: {
            disabled: this.fieldDisabled,
            clearable: true,
          },
          col: {
            md: {
              span: 8,
            },
          },
        },
        {
          title: '税号',
          type: 'input',
          field: 'taxNumber',
          props: {
            disabled: this.fieldDisabled,
            clearable: true,
          },
          col: {
            md: {
              span: 8,
            },
          },
        },
        {
          title: '法人',
          type: 'input',
          field: 'legal',
          props: {
            disabled: this.fieldDisabled,
            clearable: true,
          },
          col: {
            md: {
              span: 8,
            },
          },
        },
        {
          title: '开户银行',
          type: 'input',
          field: 'depositBank',
          props: {
            disabled: this.fieldDisabled,
            clearable: true,
          },
          col: {
            md: {
              span: 8,
            },
          },
        },
        {
          title: '银行账号',
          type: 'input',
          field: 'bankAccount',
          props: {
            disabled: this.fieldDisabled,
            clearable: true,
          },
          col: {
            md: {
              span: 8,
            },
          },
        },
        {
          title: '是否默认开票信息',
          type: 'radio',
          field: 'billMain',
          options: [
            { value: '1', label: '是' },
            { value: '0', label: '否' },
          ],
          props: {
            disabled: this.fieldDisabled,
          },
          col: {
            md: {
              span: 8,
            },
          },
        },
      ],
    };
  },
  mounted() {
    if (Object.keys(this.invoiceInfoItemData)) {
      this.setValue(this.invoiceInfoItemData);
    }
  },
  methods: {},
};
</script>
