var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "between" }, [
      _c(
        "div",
        { staticClass: "between" },
        [
          _vm._m(0),
          _c("el-input-number", {
            staticClass: "betweenInput",
            attrs: {
              disabled: _vm.disabled,
              controls: false,
              min: 0,
              precision: 0,
            },
            on: { change: _vm.changeNum },
            model: {
              value: _vm.storesData.warehouseNum,
              callback: function ($$v) {
                _vm.$set(_vm.storesData, "warehouseNum", $$v)
              },
              expression: "storesData.warehouseNum",
            },
          }),
          _c("span", { staticClass: "betweenBox" }, [_vm._v("/个")]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "between" },
        [
          _c("el-input-number", {
            staticClass: "betweenInput",
            attrs: { disabled: _vm.disabled, controls: false, min: 0 },
            on: { change: _vm.changeSquare },
            model: {
              value: _vm.storesData.warehouseSquare,
              callback: function ($$v) {
                _vm.$set(_vm.storesData, "warehouseSquare", $$v)
              },
              expression: "storesData.warehouseSquare",
            },
          }),
          _c("span", [_vm._v("/平方米")]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "betweenBox" }, [
      _c("span", { staticClass: "red" }, [_vm._v("*")]),
      _vm._v("自有仓库"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }