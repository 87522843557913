<script>
import Form from '../../../../../../components/form';

export default {
  extends: Form,
  props: {
    contactInfoData: Object,
    index: Number,
    fieldDisabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      buttons: {
        submit: false,
        close: false,
      },
      rule: [
        {
          title: '账号',
          type: 'input',
          field: 'userName',
          value: this.contactInfoData.userName,
          props: {
            clearable: true,
            disabled: this.fieldDisabled,
          },
          col: {
            md: {
              span: 8,
            },
          },
        },
        {
          title: '用户名',
          field: 'fullName',
          value: this.contactInfoData.fullName,
          props: {
            clearable: true,
            disabled: this.fieldDisabled,
          },
          type: 'input',
          col: {
            md: {
              span: 8,
            },
          },
        },
        {
          title: '用户类型',
          type: 'select',
          field: 'userType',
          options: [],
          value: this.contactInfoData.userType,
          dictCode: 'user_type',
          on: {
            change: (e) => {
              console.log(e);
            },
          },
          props: {
            filterable: true,
            disabled: this.fieldDisabled,
          },
          col: {
            md: {
              span: 8,
            },
          },
        },

      ],
    };
  },
  mounted() {
    if (Object.keys(this.contactInfoData)) {
      this.setValue(this.contactInfoData);
    }
  },
  methods: {},
};
</script>
