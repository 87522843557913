<template>
  <div>
    <div class="sale-area-container" v-for="(item,index) in extendInfoData" :key="item.id">
    <ExtendInfoItem :showProduct="showProduct" :fieldDisabled="disabled" :ref="item.id" class="sale-area-item" :extendInfoData="item"/>
    <el-button v-if="!disabled && index>0" class="del-btn" type="text" @click="del(index)" icon="el-icon-delete"></el-button>
    </div>
  </div>
</template>

<script>
import ExtendInfoItem from './extend_info_item.vue';

export default {
  components: {
    ExtendInfoItem,
  },
  props: {
    extendInfoData: Array,
    showProduct: {
      type: Boolean,
      default() {
        return true;
      },
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  watch: {
    extendInfoData(newVal, oldVal) {
      this.extendInfoData = newVal;
    },
  },

  methods: {
    del(index) {
      this.$emit('del', index);
    },
    getFormData() {
      const data = [];
      let flag = 'check';
      this.extendInfoData.forEach((v) => {
        if (this.$refs[v.id][0].getFormData()) {
          data.push(this.$refs[v.id][0].getFormData());
        } else {
          flag = 'noCheck';
        }
      });
      if (flag === 'check') {
        return data;
      }
      return 'noCheck';
    },
  },
};
</script>

<style lang="less" scoped>
  .sale-area-container{
    // display: flex;
    // align-items: top;
    position: relative;
    border-bottom: 1px solid #eee;
    padding-top:20px;

    .del-btn{
      position: absolute;
      top: 0;
      right: -16px;
      width: 20px;
      color: red;
    }
  }
</style>
