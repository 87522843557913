<template>
  <div>
    <div class="between">
      <div class="between">
        <div class="betweenBox"><span class="red">*</span>自营店铺</div>
        <el-input-number class="betweenInput" :disabled="disabled" @change="changeNum" v-model="storesData.storeNum" :controls="false" :min="0" :precision="0"></el-input-number><span class="betweenBox">/间</span>
      </div>
      <div class="between">
        <el-input-number class="betweenInput" :disabled="disabled" @change="changeSquare" v-model="storesData.storeSquare" :controls="false" :min="0"></el-input-number><span>/平方米</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SelfOperatedStores',
  props: {
    store: Object,
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  watch: {
    store(val) {
      this.storesData = val;
    },
  },
  data() {
    return {
      storesData: {
        storeNum: 0,
        storeSquare: 0,
      },
    };
  },
  methods: {
    changeNum(val) {
      this.$emit('changeNum', val);
    },
    changeSquare(val) {
      this.$emit('changeSquare', val);
    },
  },
};
</script>
<style lang="less" scoped>
  .between{
    display: flex;
  }
  .betweenInput{
      width: 150px;
      margin-right: 10px;
  }
  .betweenBox{
      margin-right: 10px;
  }
  .red{
      color: #f56c6c;
      margin-right: 4px;
      margin-left: 10px;
  }
</style>
