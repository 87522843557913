<script>
import Form from '../../../../../../components/form';

export default {
  extends: Form,
  props: {
    contactInfoData: Object,
    index: Number,
    fieldDisabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      buttons: {
        submit: false,
        close: false,
      },
      rule: [
        {
          title: `联系人${this.index + 1}姓名`,
          type: 'input',
          field: 'contactName',
          value: this.contactInfoData.contactName,
          props: {
            clearable: true,
            disabled: this.fieldDisabled,
          },
          col: {
            md: {
              span: 8,
            },
          },
        },
        {
          title: `联系人${this.index + 1}电话`,
          field: 'contactPhone',
          value: this.contactInfoData.contactPhone,
          props: {
            clearable: true,
            disabled: this.fieldDisabled,
          },
          validate: [{
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: '请输入正确的电话号码',
            trigger: 'blur',
          }],
          type: 'input',
          col: {
            md: {
              span: 8,
            },
          },
        },
        {
          title: '是否主联系人',
          type: 'select',
          field: 'contactMain',
          value: this.contactInfoData.contactMain,
          props: {
            clearable: true,
            disabled: this.fieldDisabled,
          },
          options: [
            {
              label: '是',
              value: '1',
            }, {
              label: '否',
              value: '0',
            },
          ],
          col: {
            md: {
              span: 8,
            },
          },
        },

      ],
    };
  },
  mounted() {
  },
  methods: {},
};
</script>
