var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.storesData, function (item, index) {
      return _c("div", { key: index, staticClass: "between" }, [
        _c(
          "div",
          { staticClass: "between" },
          [
            _vm._m(0, true),
            _c("el-input", {
              staticClass: "brandNameInput",
              attrs: { disabled: _vm.disabled },
              model: {
                value: item.brandName,
                callback: function ($$v) {
                  _vm.$set(item, "brandName", $$v)
                },
                expression: "item.brandName",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "between" },
          [
            _c("div", [_vm._v("销售额（万元/年）")]),
            _c("el-input-number", {
              staticClass: "betweenInput",
              attrs: { disabled: _vm.disabled, controls: false, min: 0 },
              model: {
                value: item.sales,
                callback: function ($$v) {
                  _vm.$set(item, "sales", $$v)
                },
                expression: "item.sales",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "between" },
          [
            !_vm.disabled && index > 0
              ? _c("el-button", {
                  staticClass: "danger",
                  attrs: { type: "text", icon: "el-icon-delete" },
                  on: {
                    click: function ($event) {
                      return _vm.del(index)
                    },
                  },
                })
              : _vm._e(),
            !_vm.disabled
              ? _c("el-button", {
                  staticClass: "primary",
                  attrs: { type: "text", icon: "el-icon-circle-plus-outline" },
                  on: {
                    click: function ($event) {
                      return _vm.add()
                    },
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ])
    }),
    0
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "betweenBox" }, [
      _c("span", { staticClass: "red" }, [_vm._v("*")]),
      _vm._v("品牌"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }