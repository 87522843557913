<template>
  <div>
    <div class="sale-area-container" v-for="(item,index) in overviewOfCooperation" :key="index">
    <OverviewOfCooperationItem :fieldDisabled="disabled" class="sale-area-item" :ref="item.id" :overviewOfCooperation="item"/>
    <el-button v-if="!disabled && index>0" class="del-btn" type="text" @click="del(index)" icon="el-icon-delete"></el-button>
    </div>
  </div>
</template>

<script>
import OverviewOfCooperationItem from './overview_of_cooperation_item.vue';

export default {
  components: {
    OverviewOfCooperationItem,
  },
  props: {
    overviewOfCooperation: Array,
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  watch: {
    overviewOfCooperation(newVal, oldVal) {
      this.overviewOfCooperation = newVal;
    },
  },

  methods: {
    del(index) {
      this.$emit('del', index);
    },
    getFormData() {
      const data = [];
      let flag = 'check';
      this.overviewOfCooperation.forEach((v) => {
        if (this.$refs[v.id][0].getFormData()) {
          data.push(this.$refs[v.id][0].getFormData());
        } else {
          flag = 'noCheck';
        }
      });
      if (flag === 'check') {
        return data;
      }
      return 'noCheck';
    },
  },
};
</script>

<style lang="less" scoped>
  .sale-area-container{
    // display: flex;
    // align-items: top;
    position: relative;
    border-bottom: 1px solid #eee;
    padding-top:20px;

    .del-btn{
      position: absolute;
      top: 0;
      right: -16px;
      width: 20px;
      color: red;
    }
  }
</style>
