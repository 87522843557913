<template>
  <div>
    <div class="between" v-for="(item, index) in storesData" :key="index">
      <div class="between">
        <div class="betweenBox"><span class="red">*</span>品牌</div><el-input class="brandNameInput" :disabled="disabled" v-model="item.brandName"></el-input>
      </div>
      <div class="between">
        <div>销售额（万元/年）</div><el-input-number  class="betweenInput" :disabled="disabled" v-model="item.sales" :controls="false" :min="0" ></el-input-number>
      </div>
      <div class="between">
          <el-button v-if="!disabled && index>0" class="danger" type="text" @click="del(index)" icon="el-icon-delete"></el-button>
          <el-button v-if="!disabled" class="primary" type="text" @click="add()" icon="el-icon-circle-plus-outline"></el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SelfOperatedStores',
  props: {
    brandReqs: Array,
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  watch: {
    brandReqs(val) {
      this.storesData = val;
    },
  },
  data() {
    return {
      storesData: [{
        customerCode: '',
        brandName: '',
        sales: 0,
      }],
    };
  },
  methods: {
    add() {
      this.storesData.push({
        ...this.storesData,
      });
      this.$emit('add', this.storesData);
    },
    del(index) {
      this.storesData.splice(index, 1);
      this.$emit('del', this.storesData);
    },
  },
};
</script>
<style lang="less" scoped>
  .between{
    display: flex;
    margin-bottom: 10px;
  }
  .betweenInput{
      width: 150px;
      margin-right: 10px;
  }
  .betweenBox{
      margin-right: 10px;
  }
  .red{
      color: #f56c6c;
      margin-right: 4px;
      margin-left: 10px;
  }
  .brandNameInput{
      width: 200px;
      margin-right: 10px;
  }
  .primary{
      color: #409EFF !important;
  }
  .danger{
      color: #c34231 !important;
  }
</style>
